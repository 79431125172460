<template>
  <v-card
    class="explore-mobile-details"
    color="#12368a"
    min-width="95vw"
    max-width="95vw"
    max-height="70vh"
  >
    <v-container fill-height fluid>
      <v-row dense no-gutters>
        <v-col cols="2" align-self="center">
          <v-img
            :src="require('@/assets/images/klover-focus.png')"
            :lazy-src="require('@/assets/images/klover-focus.png')"
            max-height="60px"
            max-width="60px"
            contain
            style="position: relative; right: 12%"
          />
        </v-col>
        <v-col cols="10">
          <h2 class="white--text explore-klover-focus-title">
            {{ $t("explore.focus.title") }}
          </h2>
          <p class="explore-details-container-card-subtitle mb-1">
            {{ $t("explore.focus.description") }} {{ $t("city")[city[0]].name }}
          </p>
          <v-progress-linear
            :value="greeness * 100"
            height="20"
            style="border-radius: 25px"
            color="#81c868"
          >
            <template>
              <span style="color: white; font-size: 14px"
                >{{ Math.round(greeness * 100)
                }}<span style="font-size: 14px">%</span></span
              >
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "ExploreMobileDetails",
  props: {
    city: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      collapsed: true,
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    greeness() {
      return (
        this.city[1].SurfVeg_m2 /
        (this.city[1].SurfVeg_m2 + this.city[1].SurfNoVeg_m2)
      );
    },
  },
  updated() {
    if (!this.collapsed) {
      this.manageBubblePosition();
    }
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    manageBubblePosition() {
      if (this.greeness > 0.9) {
        this.$refs.detailsGreeneryBubble.setAttribute(
          "style",
          "left : calc(" + this.greeness * 100 + "% - 52px);"
        );
        this.$refs.detailsGreeneryBubble.classList.add(
          "details-greenery-bubble-high-value"
        );
      } else {
        this.$refs.detailsGreeneryBubble.classList.remove(
          "details-greenery-bubble-high-value"
        );
        if (this.greeness < 0.1) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" +
              (this.greeness + 0.01) * 100 +
              "% - 32px); width : 60px"
          );
        } else if (this.greeness > 0.65) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 22px);"
          );
        } else if (this.greeness > 0.5) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 22px);"
          );
        } else if (this.greeness > 0.35) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 22px);"
          );
        } else {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 22px);"
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.explore-mobile-details {
  position: relative;
  left: 2%;
  bottom: 2%;
  z-index: 4;
  border-radius: 15px;
}

.explore-klover-focus-title {
  font-size: 18px;
  line-height: 1;
}

.explore-details-container-card-subtitle {
  font-size: 14px;
  color: #85b0ea;
}

.explore-details-container-content {
  color: white;
  margin-bottom: 10%;
}

.details-greenery-bubble-percentage {
  font-size: 16px;
  top: 12%;
  left: 15%;
}

.details-greenery-bubble {
  width: 45px;
  height: 30px;
}

.details-greenery-bubble::after {
  all: unset;
}

.explore-details-container-value {
  color: white;
}

.explore-details-container-value-text {
  color: #86b0ee;
  font-size: 14px;
}

.explore-klover-focus-title-collapsed {
  margin: auto;
  font-size: 24px;
  font-weight: inherit;
}
</style>
