<template>
  <div>
    <Explore v-if="!isMobile" />
    <ExploreMobile v-if="isMobile" />
  </div>
</template>

<script>
import Explore from "@/components/Explore/Explore";
import ExploreMobile from "@/components/Explore/ExploreMobile";

export default {
  name: "ExploreRedirect",
  components: {
    Explore,
    ExploreMobile,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.isMobile = window.innerWidth <= 800;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth <= 800;
    });
  },
};
</script>
