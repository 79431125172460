<!-- Similar to Explore.vue except HTML and css are for mobile version -->
<template>
  <div id="MainExploreDiv" ref="MainExploreDiv" class="main-explore-div">
    <div id="container" ref="container"></div>
    <ExploreMobileChoice
      :city="city"
      :data="cityArray"
      @updateCity="updateCity($event)"
    />
    <div style="position: absolute; bottom: 2%">
      <!-- <ExploreMobileStat class="explore-mobile-stat" :hide="displayStat" :city="city"/> -->
      <v-row class="main-stats mb-1" dense align="center" justify="center">
        <v-col cols="2" style="text-align: end">
          <v-icon size="35px" color="#69b870"
            >$vuetify.icon.klover_score</v-icon
          >
        </v-col>
        <v-col>
          <p class="explore-details-container-value">
            {{ Math.round(city[1].PercVeg) }}
            <span style="font-size: 14px">/100</span>
          </p>
          <p class="explore-details-container-value-text">
            {{ $t("explore.stat1") }}
          </p>
        </v-col>
        <v-col v-if="city[1].weather" cols="2" style="text-align: end">
          <v-icon size="35px" color="#69b870"
            >$vuetify.icon.mean_deviation</v-icon
          >
        </v-col>
        <v-col v-if="city[1].weather">
          <p class="explore-details-container-value">
            {{
              (city[1].weather.meanDeviationYearly.C[
                city[1].weather.meanDeviationYearly.C.length - 1
              ] >= 0
                ? "+"
                : "") +
              $n(
                city[1].weather.meanDeviationYearly.C[
                  city[1].weather.meanDeviationYearly.C.length - 1
                ].toFixed(2)
              ) +
              "°C"
            }}
          </p>
          <p class="explore-details-container-value-text">
            {{ $t("explore.stat2") }} {{ currentYear }}
          </p>
        </v-col>
      </v-row>
      <ExploreMobileDetails v-if="!this.displayStat" :city="city" />
    </div>
  </div>
</template>

<script>
import kloverData from "@/assets/klover_data.json";
import earthTexture from "@/assets/images/earth-green.png";
import yellowMarkerTexture from "@/assets/images/marker.png";
import blueMarkerTexture from "@/assets/images/blueMarker.png";
import ExploreMobileChoice from "@/components/Explore/ExploreMobileChoice";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import TWEEN from "@tweenjs/tween.js";
import axios from "axios";
import ExploreMobileDetails from "@/components/Explore/ExploreMobileDetails";

export default {
  name: "ExploreMobile",
  components: {
    ExploreMobileChoice,
    ExploreMobileDetails,
  },
  metaInfo() {
    var result;
    var resultTitle;
    if (window.location.pathname === "/explorer") {
      resultTitle = "Explorer - Klover";
      result = [
        {
          name: "title",
          content: "Klover : les villes vertes à travers le monde",
        },
        {
          name: "description",
          content:
            "Explorez la planète pour découvrir la place de la végétation dans 24 grandes villes à travers le monde",
        },
        { property: "og:type", content: "website" },
        {
          property: "og:url",
          content: "https://klover.city/explorer",
        },
        {
          property: "og:title",
          content: "Klover : les villes vertes à travers le monde",
        },
        {
          property: "og:description",
          content:
            "Explorez la planète pour découvrir la place de la végétation dans 24 grandes villes à travers le monde",
        },
        {
          property: "og:image",
          content: "https://klover.city/meta-img/explore-card-fr.jpg",
        },
        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://klover.city/explorer",
        },
        {
          property: "twitter:title",
          content: "Klover : les villes vertes à travers le monde",
        },
        {
          property: "twitter:description",
          content:
            "Explorez la planète pour découvrir la place de la végétation dans 24 grandes villes à travers le monde",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/explore-card-fr.jpg",
        },
      ];
    } else {
      resultTitle = "Explore - Klover";
      result = [
        {
          name: "title",
          content: "Klover : green cities around the world",
        },
        {
          name: "description",
          content:
            "Explore the planet to discover how much room vegetation gets in 24 big cities around the world",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://klover.city/explore" },
        {
          property: "og:title",
          content: "Klover : green cities around the world",
        },
        {
          property: "og:description",
          content:
            "Explore the planet to discover how much room vegetation gets in 24 big cities around the world",
        },
        {
          property: "og:image",
          content: "https://klover.city/meta-img/explore-card-en.jpg",
        },
        { property: "twitter:card", content: "summary_large_image" },
        { property: "twitter:url", content: "https://klover.city/explore" },
        {
          property: "twitter:title",
          content: "Klover : green cities around the world",
        },
        {
          property: "twitter:description",
          content:
            "Explore the planet to discover how much room vegetation gets in 24 big cities around the world",
        },
        {
          property: "twitter:image",
          content: "https://klover.city/meta-img/explore-card-en.jpg",
        },
      ];
    }
    return {
      title: resultTitle,
      meta: result,
    };
  },
  data() {
    return {
      cityArray: Object.entries(kloverData),
      currentYear: new Date().getFullYear(),
      showMarkerList: false,
      showDetails: false,
      container: null,
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      controls: null,
      raycaster: null,
      mouse: null,
      markers: [],
      city: null,
      selectedMarker: null,
      earthRadius: 200,
      earthTexture: earthTexture,
      yellowMarkerTexture: yellowMarkerTexture,
      blueMarkerTexture: blueMarkerTexture,
      initialHeight: 0,
      isScrolling: null,
      lastSelected: null,
      displayStat: true,
    };
  },
  created() {
    this.city = this.cityArray[0];
  },
  async mounted() {
    var heights = window.innerHeight;
    this.$refs.MainExploreDiv.style.height = heights - 65 + "px";
    this.$refs.container.style.height = heights - 170 + "px";
    var self = this;
    window.addEventListener("resize", function () {
      var heights = window.innerHeight;
      self.$refs.MainExploreDiv.style.height = heights - 65 + "px";
      self.$refs.container.style.height = heights - 170 + "px";
      self.onWindowResize();
    });
    this.city = this.cityArray[0];
    this.init();
    this.addLights();
    this.addEarth();
    await this.getWeatherData();
    this.addMarkers();
    this.animate();
    this.$nextTick().then(this.handleScroll);
  },
  methods: {
    // Close the details and reset the selected marker color
    closeDetails() {
      this.showDetails = false;
    },
    // Initialize the earth globe in the #container div
    init() {
      this.container = this.$refs.container;
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

      // Add the scene
      this.scene = new THREE.Scene();
      // this.scene.background = new THREE.Color(0x52bffa);
      this.renderer.setClearColor(0x000000, 0);

      //  Instantiate a raycaster. It is a virtual ray emit by the mouse. It allows to detect click event when this ray intersects an object
      this.raycaster = new THREE.Raycaster();
      window.addEventListener("click", this.raycast, false);
      window.addEventListener("pointerdown", this.raycastMobile, false);
      //Instantiate the mouse which allows to detect click event
      this.mouse = new THREE.Vector3();
      this.initialHeight = this.container.clientHeight;
      // Instantiate a camera to the scene. It allows to see objects.
      this.camera = new THREE.PerspectiveCamera(
        50,
        this.container.clientWidth / this.initialHeight,
        1,
        10000
      );
      // Orbit controls allows to turn around the earth
      this.controls = new OrbitControls(this.camera, this.container);
      this.controls.enablePan = false;
      this.controls.enableZoom = false;
      this.controls.enableDamping = true;

      // set the camera position to see the earth
      this.camera.position.z = 500;
      this.controls.update();
      // Set the size of the renderer
      this.renderer.setSize(this.container.clientWidth, this.initialHeight);
      // Add the renderer to the div
      this.container.appendChild(this.renderer.domElement);
      // Add the scene and the camera to the renderer
      this.renderer.render(this.scene, this.camera);

      this.renderer.setPixelRatio(window.devicePixelRatio);

      // Add the "resize" event listener which manages the positioning on resizing
      window.onresize = this.onWindowResize;

      // load the two textures of markers
      this.yellowSpriteMap = new THREE.TextureLoader().load(
        this.yellowMarkerTexture
      );
      this.blueSpriteMap = new THREE.TextureLoader().load(
        this.blueMarkerTexture
      );
    },
    // redirect scroll on the whole page to the explore-city-list
    redirectScroll(event) {
      this.$refs.exploreCityList.scrollTo({
        top: this.$refs.exploreCityList.scrollTop + event.deltaY * 3,
        behavior: "smooth",
      });
    },
    // This method detects the first object which intersects the ray emitted by the mouse. Basically, it detects user clicks
    raycast(event) {
      if (window.chrome) {
        // rect contains data about the current div (position, offset, etc.)
        let rect = this.renderer.domElement.getBoundingClientRect();
        // calculate mouse position
        this.mouse.x =
          ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
        this.mouse.y =
          -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
        this.raycaster.setFromCamera(this.mouse, this.camera);
        // compute intersections
        let intersects = this.raycaster.intersectObjects(this.scene.children);

        // detect if the object clicked is a marker
        if (intersects[0] != null && intersects[0].object.data != null) {
          if (
            this.selectedMarker != null &&
            this.selectedMarker != intersects[0].object
          ) {
            // reset the color of the selected marker
            this.selectedMarker.material = new THREE.SpriteMaterial({
              map: this.yellowSpriteMap,
            });
          }
          // set a new color to the selected marker
          this.selectedMarker = intersects[0].object;
          this.selectedMarker.material = new THREE.SpriteMaterial({
            map: this.blueSpriteMap,
          });
          // get the city of the selected marker
          this.city = intersects[0].object.data;

          this.moveToMarker(this.selectedMarker);
        }
      }
    },
    raycastMobile(event) {
      // rect contains data about the current div (position, offset, etc.)
      let rect = this.renderer.domElement.getBoundingClientRect();
      // calculate mouse position
      this.mouse.x =
        ((event.clientX - rect.left) / (rect.width - rect.left)) * 2 - 1;
      this.mouse.y =
        -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
      this.raycaster.setFromCamera(this.mouse, this.camera);
      // compute intersections
      let intersects = this.raycaster.intersectObjects(this.scene.children);
      // detect if the object clicked is a marker
      if (intersects[0] != null && intersects[0].object.data != null) {
        if (
          this.selectedMarker != null &&
          this.selectedMarker != intersects[0].object
        ) {
          // reset the color of the selected marker
          this.selectedMarker.material = new THREE.SpriteMaterial({
            map: this.yellowSpriteMap,
          });
        }
        // set a new color to the selected marker
        this.selectedMarker = intersects[0].object;
        this.selectedMarker.material = new THREE.SpriteMaterial({
          map: this.blueSpriteMap,
        });
        // get the city of the selected marker
        this.city = intersects[0].object.data;
        this.moveToMarker(this.selectedMarker);
      }
    },
    // Resizing handler
    onWindowResize() {
      // keep the camera ratio
      this.camera.aspect =
        this.$refs.container.clientWidth / this.$refs.container.clientHeight;
      this.camera.updateProjectionMatrix();

      // resize the current div
      this.renderer.setSize(
        this.$refs.container.clientWidth,
        this.$refs.container.clientHeight
      );
    },
    // Move to clicked marker
    moveToMarker(marker) {
      let from = this.camera.position;
      // new position of the camera
      let to = {
        x: marker.position.x * 3.4,
        y: marker.position.y * 3.4,
        z: marker.position.z * 3.4,
      };
      //  smooth transition of the camera to the picked marker
      let tween = new TWEEN.Tween(from);
      tween.to(to, 1000).easing(TWEEN.Easing.Sinusoidal.Out).start();

      // set a new color to the selected marker
      this.selectedMarker = marker;
      this.selectedMarker.material = new THREE.SpriteMaterial({
        map: this.blueSpriteMap,
      });
    },
    // Move to the first visible city on list
    moveToMarkerScroll(marker) {
      if (this.selectedMarker != null) {
        // reset the color of the selected marker
        this.selectedMarker.material = new THREE.SpriteMaterial({
          map: this.yellowSpriteMap,
        });
        // reset font of the selected marker

        /* this.$refs.exploreCityListItem[
          this.selectedMarker.index
        ].children[0].style.webkitTextStroke = "1px #113689";

        this.$refs.exploreCityListItem[
          this.selectedMarker.index
        ].children[0].style.color = "#00000000"; */
      }
      // set a new color to the selected marker
      this.selectedMarker = marker;
      this.selectedMarker.material = new THREE.SpriteMaterial({
        map: this.blueSpriteMap,
      });
      // get the city of the selected marker
      this.city = marker.data;

      let from = this.camera.position;
      // new position of the camera
      let to = {
        x: marker.position.x * 3.4,
        y: marker.position.y * 3.4,
        z: marker.position.z * 3.4,
      };
      // smooth transition of the camera to the picked marker
      let tween = new TWEEN.Tween(from);
      tween.to(to, 1000).easing(TWEEN.Easing.Sinusoidal.Out).start();

      // check if the city list item DOM element is undefined if it is not rendered yet, for instance when we call this method for the first city
      /* if (this.$refs.exploreCityListItem != undefined) {
        // change font of the selected city
        this.$refs.exploreCityListItem[
          this.selectedMarker.index
        ].children[0].style.webkitTextStroke = "unset";

        this.$refs.exploreCityListItem[
          this.selectedMarker.index
        ].children[0].style.color = "#113689";
      } */
      // display the details
      this.showDetails = true;
    },
    // Add lights to the scene. Objects are invisible without light
    addLights() {
      // soft white light
      let light2 = new THREE.AmbientLight(0xffffff);
      this.scene.add(light2);
    },
    // Add earth to the scene
    addEarth() {
      // create a new sphere
      let geometry = new THREE.SphereGeometry(this.earthRadius, 50, 50);
      // load the earth texture
      const texture = new THREE.TextureLoader().load(this.earthTexture);
      // create the new earth texture
      let material = new THREE.MeshBasicMaterial({
        map: texture,
      });
      // apply the earth texture to te sphere
      this.mesh = new THREE.Mesh(geometry, material);
      // add the sphere to the scene
      this.scene.add(this.mesh);
    },
    // Add object on the earth surface thanks to geographic coordinates
    placeObjectOnPlanet(object, lat, lon, radius) {
      lat += 1;
      let latRad = lat * (Math.PI / 180);
      let lonRad = -lon * (Math.PI / 180);
      object.position.set(
        Math.cos(latRad) * Math.cos(lonRad) * radius,
        Math.sin(latRad) * radius,
        Math.cos(latRad) * Math.sin(lonRad) * radius
      );
      // object.rotation.set(0.0, -lonRad, latRad - Math.PI * 0.5);
      object.rotateZ(Math.PI);
    },

    // add markers on the earth
    addMarkers() {
      let markerMaterial = [];

      for (let i = 0; i < this.cityArray.length; i++) {
        // load the city data
        const data = this.cityArray[i];
        // create the marker
        markerMaterial[i] = new THREE.SpriteMaterial({
          map: this.yellowSpriteMap,
        });
        this.markers[i] = new THREE.Sprite(markerMaterial[i]);
        this.markers[i].scale.set(17, 17, 17);
        this.scene.add(this.markers[i]);
        // place the marker
        this.placeObjectOnPlanet(
          this.markers[i],
          data[1].center[0],
          data[1].center[1],
          this.earthRadius + 8
        );
        // load the city data in the marker object
        this.markers[i].data = data;
        this.markers[i].index = i;
        // add the cone the scene
        this.scene.add(this.markers[i]);
      }
      this.showMarkerList = true;
      this.moveToMarkerScroll(this.markers[0]);
    },
    // Refresh the scene
    animate() {
      TWEEN.update();

      requestAnimationFrame(this.animate);
      this.controls.update();

      this.renderer.render(this.scene, this.camera);
    },
    updateCity(newVal) {
      if (newVal !== this.city[0]) {
        var self = this;
        this.cityArray.forEach(function (entry) {
          if (newVal === entry[0]) {
            self.city = entry;
            self.markers.forEach((marker) => {
              if (marker.data[0] === self.city[0]) {
                self.selectedMarker.material = new THREE.SpriteMaterial({
                  map: self.yellowSpriteMap,
                });
                self.moveToMarker(marker);
              }
            });
          }
        });
      }
    },
    // get weather data
    async getWeatherData() {
      let meanDeviationResponses;
      try {
        // Get data from local Nasa Power api for historical weather information for charts
        meanDeviationResponses = await axios.get(
          process.env.VUE_APP_NASA_POWER_LOCAL_URL + "/mean_deviation_yearly"
        );
        meanDeviationResponses = meanDeviationResponses.data;
        // Add weather data to the city array
        const meanDeviationResponsesArray = Object.entries(
          meanDeviationResponses
        );

        for (
          let index = 0;
          index < meanDeviationResponsesArray.length;
          index++
        ) {
          this.cityArray[index][1].weather = {};
          this.cityArray[index][1].weather.meanDeviationYearly =
            meanDeviationResponsesArray[index][1];
        }
        this.displayStat = false;
      } catch (error) {
        console.error(error);
      }
    },
    /* updateDisplayStat(value) {
      this.displayStat = !value;
    }, */
  },
};
</script>

<style scoped>
.main-explore-div {
  background: rgb(119, 201, 255);
  background: url(images/Graphic-lines.svg),
    linear-gradient(
      170deg,
      rgba(119, 201, 255, 1) 0%,
      rgba(76, 155, 224, 1) 50%,
      rgba(13, 80, 157, 1) 100%
    );
  background-size: cover;
  background-repeat: no-repeat;
}

#container {
  outline-width: 0;
  height: calc(100vh - 170px);
  width: 100%;
  z-index: 2;
  position: relative;
}
canvas {
  height: 100%;
  width: 100%;
}

.sun {
  height: 250px;
  width: 250px;
  background-color: yellow;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 33%;
  top: 5%;
  z-index: 1;
}

@media screen and (max-width: 800px) {
  .main-explore-div {
    height: calc(100vh - 65px);
    width: 100vw;
  }

  .sun {
    height: 125px;
    width: 125px;
    left: 10%;
    top: 7%;
  }

  .title-explore-mobile {
    position: absolute;
    font-family: "Albra Bold";
    color: #113689;
    font-size: 32px;
    font-display: swap;
  }

  .main-stats {
    max-width: 95vw;
    z-index: 3;
  }

  .explore-details-container-value {
    color: white;
    font-weight: bold;
  }

  .explore-details-container-value-text {
    color: white;
    font-size: 14px;
    line-height: 1.1;
  }
}

.explore-mobile-stat {
  position: relative;
  z-index: 2;
  margin: 2%;
}
</style>
