<template>
  <div class="explore-details-container">
    <v-row>
      <v-icon class="mr-3" size="2vw" color="#f5cc3e">mdi-map-marker</v-icon>
      <h1 class="explore-details-container-title">
        {{ $t("explore.title") }}
      </h1>
    </v-row>
    <v-row>
      <p class="white--text explore-details-container-city">
        {{ $t("city")[city[0]].name }}, {{ $t("city")[city[0]].country }}
      </p>
    </v-row>
    <v-row class="pt-5" no-gutters>
      <v-col>
        <v-icon size="2.9vw" color="#69b870">$vuetify.icon.klover_score</v-icon>
        <p class="explore-details-container-value">
          {{ Math.round(city[1].PercVeg) }}
          <span style="font-size: 0.8vw">/100</span>
        </p>
        <p class="explore-details-container-content">
          {{ $t("explore.stat1") }}
        </p>
      </v-col>
      <v-col v-if="city[1].weather" cols="7">
        <v-col>
          <v-icon size="2.3vw" color="#69b870"
            >$vuetify.icon.mean_deviation</v-icon
          >
          <p class="explore-details-container-value">
            {{
              (city[1].weather.meanDeviationYearly.C[
                city[1].weather.meanDeviationYearly.C.length - 1
              ] >= 0
                ? "+"
                : "") +
              $n(
                city[1].weather.meanDeviationYearly.C[
                  city[1].weather.meanDeviationYearly.C.length - 1
                ].toFixed(2)
              ) +
              "°C"
            }}
          </p>
          <p class="explore-details-container-content">
            {{ $t("explore.stat2") }} <b>{{ currentYear }}</b>
          </p>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-card
        shaped
        color="#12368a"
        min-width="90%"
        max-width="100%"
        min-height="15vw"
      >
        <v-container fill-height fluid>
          <v-row no-gutters>
            <v-col>
              <v-img
                :src="require('@/assets/images/klover-focus.png')"
                :lazy-src="require('@/assets/images/klover-focus.png')"
                max-height="5vw"
                max-width="5vw"
                contain
              />
            </v-col>
            <v-col cols="8">
              <h2 class="white--text explore-klover-focus-title">
                {{ $t("explore.focus.title") }}
              </h2>
              <p class="explore-details-container-card-subtitle">
                {{ $t("explore.focus.description") }}
                {{ $t("city")[city[0]].name }}
                <template v-if="$i18n.locale === 'en'">
                  {{ $t("explore.focus.description2") }}
                </template>
              </p>
            </v-col>
          </v-row>
          <v-row class="ma-0">
            <v-divider color="#336db9" class="mb-2 mt-2" />
          </v-row>
          <v-row class="ma-2">
            <div ref="detailsGreeneryBubble" class="details-greenery-bubble">
              <div class="details-greenery-bubble-percentage">
                {{ Math.round(greeness * 100)
                }}<span style="font-size: 15px">%</span>
              </div>
            </div>
            <v-progress-linear
              :value="greeness * 100"
              height="10"
              rounded
              color="#81c868"
            />
          </v-row>
          <p class="explore-details-container-content ma-2 mt-0">
            {{ $t("explore.focus.surface") }}
          </p>
        </v-container>
      </v-card>
    </v-row>
    <v-row>
      <a
        class="explore-details-container-btn"
        :href="$i18n.locale === 'fr' ? '/ville/' + city[0] : '/' + city[0]"
      >
        {{ $t("explore.button") }}
        <v-icon class="ml-2" color="#11388a">mdi-chevron-right</v-icon>
      </a>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ExploreDetails",
  props: {
    city: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      greeness: null,
    };
  },
  created() {
    this.greeness =
      this.city[1].SurfVeg_m2 /
      (this.city[1].SurfVeg_m2 + this.city[1].SurfNoVeg_m2);
  },
  mounted() {
    this.greeness =
      this.city[1].SurfVeg_m2 /
      (this.city[1].SurfVeg_m2 + this.city[1].SurfNoVeg_m2);
    this.manageBubblePosition();
  },
  beforeUpdate() {
    this.greeness =
      this.city[1].SurfVeg_m2 /
      (this.city[1].SurfVeg_m2 + this.city[1].SurfNoVeg_m2);
  },
  updated() {
    this.manageBubblePosition();
  },

  methods: {
    closeDetails() {
      this.$emit("close");
    },
    learnMoreAnimation() {
      this.$anime({
        targets: ".details-btn-icon",
        translateX: 15,
        direction: "reverse",
        duration: 500,
        easing: "easeInOutElastic",
      });
    },
    // Adapt bubble position depending on greenness value
    manageBubblePosition() {
      if (this.greeness > 0.9) {
        this.$refs.detailsGreeneryBubble.setAttribute(
          "style",
          "left : calc(" + this.greeness * 100 + "% - 52px);"
        );
        this.$refs.detailsGreeneryBubble.classList.add(
          "details-greenery-bubble-high-value"
        );
      } else {
        this.$refs.detailsGreeneryBubble.classList.remove(
          "details-greenery-bubble-high-value"
        );
        if (this.greeness < 0.1) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" +
              (this.greeness + 0.01) * 100 +
              "% - 32px); width : 60px"
          );
        } else if (this.greeness > 0.65) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 37px);"
          );
        } else if (this.greeness > 0.5) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 37px);"
          );
        } else if (this.greeness > 0.35) {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 37px);"
          );
        } else {
          this.$refs.detailsGreeneryBubble.setAttribute(
            "style",
            "left : calc(" + this.greeness * 100 + "% - 37px);"
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.explore-details-container {
  position: absolute;
  left: inherit;
  right: 2%;
  top: 45%;
  width: 25%;
  transform: translateY(-50%);
  z-index: 3;
}

.explore-details-container-title {
  color: white;
  font-size: 1.7vw;
}

.explore-details-container-value {
  color: white;
  font-weight: bold;
  font-size: 1.5vw;
}

.explore-details-container-content {
  color: white;
  font-size: 1.05vw;
}

.explore-details-container-card-subtitle {
  color: #759edf;
  font-size: 1.05vw;
}

.explore-details-container-btn {
  position: relative;
  top: 24px;
  color: #113689 !important;
  text-decoration: none;
  padding: 4% 6% 4% 6%;
  border: 2px solid #ffd038;
  border-radius: 30px;
  background-color: #ffd038;
  font-size: 0.9vw;
}

.explore-details-container-city {
  margin-left: 11% !important;
  font-size: 0.85vw;
}

.explore-klover-focus-title {
  font-size: 1.3vw;
}
</style>
