<template>
  <div class="explore-mobile-choice">
    <div class="text-content">
      <p class="explore-mobile-choice-title">
        {{ $t("city." + city[0] + ".name") }}
      </p>
      <p class="explore-mobile-choice-country">
        {{ $t("city." + city[0] + ".country") }}
      </p>
    </div>
    <v-btn
      color="#ffd038"
      fab
      elevation="0"
      class="explore-mobile-choice-city-list-btn-redirect"
      :href="$i18n.locale === 'fr' ? '/ville/' + city[0] : '/' + city[0]"
    >
      <v-icon size="32px" color="#113689">mdi-chevron-right</v-icon>
    </v-btn>
    <!-- <v-menu bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="explore-mobile-choice-city-list-btn"
          color="#ffd038"
          fab
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon size="32px" color="#113689">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list class="overflow-y-auto" style="max-height: 200px">
        <v-list-item
          v-for="(d, index) in data"
          :key="index"
          :inactive="d[0] !== city[0] ? false : true"
          :ripple="d[0] !== city[0] ? true : false"
          @click="updateCity(d[0])"
        >
          <v-list-item-title v-if="d[0] !== city[0]">{{
            $t("city." + d[0] + ".name")
          }}</v-list-item-title>
          <v-list-item-title v-if="d[0] === city[0]"
            ><span style="color: #e8e8e8">{{
              $t("city." + d[0] + ".name")
            }}</span></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu> -->
  </div>
</template>

<script>
export default {
  name: "ExploreMobileChoice",
  props: {
    city: {
      required: true,
      type: Array,
    },
    data: {
      required: true,
      type: Array,
    },
  },
  methods: {
    updateCity(newVal) {
      if (newVal !== this.city[0]) {
        this.$emit("updateCity", newVal);
      }
    },
  },
};
</script>

<style scoped>
.explore-mobile-choice {
  z-index: 4;
  position: absolute;
  top: 2%;
  left: 5%;
  width: 100%;
}

.explore-mobile-choice-title {
  font-family: "Albra Bold";
  color: #113689;
  font-size: 32px;
  z-index: 999;
  font-display: swap;
}

.explore-mobile-choice-country {
  color: #113689;
  font-size: 18px;
  z-index: 999;
  position: relative;
  bottom: 10px;
}

.explore-mobile-choice-city-list-btn {
  position: absolute;
  right: 30%;
  top: 5%;
}

.explore-mobile-choice-city-list-btn-redirect {
  position: absolute;
  right: 10%;
  top: 5%;
}
</style>
